<template>
  <div>
    <b-row>
      <b-col cols="12" md="12" xl="9">
        <b-card header="3D Visualization">
          <div class="graph_3d_container" ref="graph_3d_container">
            <resize-observer @notify="handleResize" />
            <div id="graph_3d" ref="graph_3d"></div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" md="12" xl="3">
        <b-row>
          <b-col cols="6" md="6" xl="12">
            <b-card header="Settings">
              <b-button-group size="sm">
                <b-button @click="request()" variant="primary" v-b-tooltip.hover title="Reload Introspect">
                  <i class="fa fa-sync-alt"></i>
                </b-button>
                <b-button @click="clearIntrospect()" variant="warning" v-b-tooltip.hover title="Clear Introspect">
                  <i class="fa fa-trash"></i>
                </b-button>
              </b-button-group>
              <hr />
              <b-button-group size="sm">
                <c-switch
                  v-b-tooltip.hover
                  title="Auto Save"
                  class="mx-2"
                  v-model="enable_auto_save"
                  color="success"
                  label
                  outline
                  :dataOn="'\u2713'"
                  :dataOff="'\u2715'"
                />
                <b-button @click="clearData()" variant="danger" v-b-tooltip.hover title="Clear">
                  <i class="fa fa-trash"></i>
                </b-button>
                <b-button @click="getStorage()" variant="primary" v-b-tooltip.hover title="Get">
                  <i class="fa fa-archive"></i>
                </b-button>
                <b-button @click="setStorage()" variant="success" v-b-tooltip.hover title="Save">
                  <i class="fa fa-save"></i>
                </b-button>
                <b-button @click="exportJSON()" variant="secondary" v-b-tooltip.hover title="Export">
                  <i class="fa fa-download"></i>
                </b-button>
                <b-button @click="update()" variant="warning" v-b-tooltip.hover title="Reload">
                  <i class="fa fa-sync-alt"></i>
                </b-button>
                <b-button @click="center()" variant="light" v-b-tooltip.hover title="Center">
                  <i class="fa fa-crosshairs"></i>
                </b-button>
              </b-button-group>
              <hr />
              <b-input-group>
                <b-form-file v-model="file" :state="Boolean(file)" accept="application/json" placeholder="Import JSON file..."></b-form-file>
                <b-input-group-append>
                  <b-button @click="importJSON()" variant="secondary" :disabled="!Boolean(file)"> <i class="fa fa-upload"></i> Import </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion_custom_classes variant="light">Custom Classes</b-button>
              </b-card-header>
              <b-collapse id="accordion_custom_classes" accordion="accordion_group" role="tabpanel">
                <b-card-body>
                  <b-form-group class="mb-0">
                    <b-input-group>
                      <b-form-input v-model.trim="add_class_name" placeholder="Class Name" />
                    </b-input-group>
                  </b-form-group>
                  <b-form-group class="mb-0">
                    <b-input-group>
                      <b-form-input v-model.trim="add_class_group" placeholder="Group Name" />
                    </b-input-group>
                  </b-form-group>
                  <b-form-group class="mb-0">
                    <b-input-group>
                      <b-form-input v-model.trim="add_class_notes" placeholder="Notes" />
                      <b-input-group-append>
                        <b-btn variant="success" @click="addClass()">
                          <i class="fa fa-plus"></i>
                        </b-btn>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>

                  <hr />

                  <b-form-group class="mb-0">
                    <b-input-group>
                      <b-form-input v-model.trim="filter_custom_classes" placeholder="Type to Search" />
                      <b-input-group-append>
                        <b-btn
                          :disabled="!filter_custom_classes && !classes_sort_by"
                          @click="
                            filter_custom_classes = '';
                            classes_sort_by = null;
                          "
                          >Clear</b-btn
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <hr />
                  <b-table
                    hover
                    small
                    responsive
                    striped
                    :fields="[
                      { key: 'actions', label: '' },
                      { key: 'class_name', label: 'Class Name', sortable: true },
                      { key: 'group', sortable: true },
                      { key: 'notes' },
                    ]"
                    :items="custom_classes"
                    :filter="filter_custom_classes"
                    :sort-by.sync="classes_sort_by"
                    @row-clicked="
                      (item, index) => {
                        toggleDetails(custom_classes, index);
                      }
                    "
                    sticky-header="750px"
                  >
                    <!-- @context-changed="(ctx) => {class_table_ordering_enabled = (_.isNil(ctx.sortBy) && _.isNil(ctx.filter)) }" -->
                    <template v-slot:cell(actions)="row">
                      <b-button-group size="sm">
                        <div @click="value => update()">
                          <c-switch
                            class="mx-2"
                            v-model="switch_classes[row.item['class_name']]"
                            color="success"
                            size="sm"
                            label
                            outline
                            :dataOn="'\u2713'"
                            :dataOff="'\u2715'"
                            :value="true"
                          />
                        </div>
                        <b-button size="sm" variant="danger" @click="removeClass(row.item['class_name'])">
                          <i class="fa fa-minus"></i>
                        </b-button>
                        <b-button size="sm" variant="light" @click="center(row.item['class_name'])">
                          <i class="fa fa-crosshairs"></i>
                        </b-button>
                        <b-button size="sm" variant="dark" :disabled="!class_table_ordering_enabled" @click="move(custom_classes, row.index, -1)">
                          <i class="fa fa-sort-up"></i>
                        </b-button>
                        <b-button size="sm" variant="secondary" :disabled="!class_table_ordering_enabled" @click="move(custom_classes, row.index, 1)">
                          <i class="fa fa-sort-down"></i>
                        </b-button>
                      </b-button-group>
                    </template>

                    <template v-slot:cell(notes)="row">
                      <nobr>{{ row.item['notes'] }}</nobr>
                    </template>

                    <template v-slot:row-details="row">
                      <div>
                        <b-form-group class="mb-0">
                          <b-input-group>
                            <b-form-input v-model.trim="row.item['class_name']" placeholder="Class Name" @input="visualizeDebouncedLong()" />
                          </b-input-group>
                        </b-form-group>
                        <b-form-group class="mb-0">
                          <b-input-group>
                            <b-form-input v-model.trim="row.item['group']" placeholder="Group Name" @input="visualizeDebouncedLong()" />
                          </b-input-group>
                        </b-form-group>
                        <b-form-group class="mb-0">
                          <b-input-group>
                            <b-form-input v-model.trim="row.item['notes']" placeholder="Notes" @input="visualizeDebouncedLong()" />
                          </b-input-group>
                        </b-form-group>
                      </div>
                    </template>
                  </b-table>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion_custom_relationships variant="light">Custom Relationships</b-button>
              </b-card-header>
              <b-collapse id="accordion_custom_relationships" accordion="accordion_group" role="tabpanel">
                <b-card-body>
                  <b-form-group class="mb-0">
                    <b-input-group>
                      <b-form-input v-model.trim="add_from_class_name" placeholder="From" />
                      <b-form-input v-model.trim="add_relationship_name" placeholder="Rel. Name" />
                      <b-form-input v-model.trim="add_to_class_name" placeholder="To" />
                    </b-input-group>
                  </b-form-group>
                  <b-form-group class="mb-0">
                    <b-input-group>
                      <b-form-input v-model.trim="add_relationship_group" placeholder="Group Name" />
                    </b-input-group>
                  </b-form-group>
                  <b-form-group class="mb-0">
                    <b-input-group>
                      <b-form-input v-model.trim="add_relationship_notes" placeholder="Notes" />
                      <b-input-group-append>
                        <b-btn variant="success" @click="addRelationship()">
                          <i class="fa fa-plus"></i>
                        </b-btn>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>

                  <hr />

                  <b-form-group class="mb-0">
                    <b-input-group>
                      <b-form-input v-model.trim="filter_custom_relationships" placeholder="Type to Search" />
                      <b-input-group-append>
                        <b-btn
                          :disabled="!filter_custom_relationships && !relationships_sort_by"
                          @click="
                            filter_custom_relationships = '';
                            relationships_sort_by = null;
                          "
                          >Clear</b-btn
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <hr />
                  <b-table
                    hover
                    small
                    responsive
                    striped
                    :fields="[
                      { key: 'actions', label: '' },
                      { key: 'from_class_name', label: 'From', sortable: true },
                      { key: 'relationship_name', label: 'Rel. Name', sortable: true },
                      { key: 'to_class_name', label: 'To', sortable: true },
                      { key: 'group', sortable: true },
                      { key: 'notes' },
                    ]"
                    :items="custom_relationships"
                    :filter="filter_custom_relationships"
                    :sort-by.sync="relationships_sort_by"
                    @row-clicked="
                      (item, index) => {
                        toggleDetails(custom_relationships, index);
                      }
                    "
                    sticky-header="750px"
                  >
                    <!-- @context-changed="(ctx) => {relationships_table_ordering_enabled = (_.isNil(ctx.sortBy) && _.isNil(ctx.filter)) }" -->
                    <template v-slot:cell(actions)="row">
                      <b-button-group size="sm">
                        <div @click="value => update()">
                          <c-switch
                            class="mx-2"
                            v-model="switch_relationships[getRelationshipId(row.item)]"
                            color="success"
                            size="sm"
                            label
                            outline
                            :dataOn="'\u2713'"
                            :dataOff="'\u2715'"
                            :value="true"
                          />
                        </div>
                        <b-button size="sm" variant="danger" @click="removeRelationship(row.item)">
                          <i class="fa fa-minus"></i>
                        </b-button>
                        <b-button size="sm" variant="dark" :disabled="!relationships_table_ordering_enabled" @click="move(custom_relationships, row.index, -1)">
                          <i class="fa fa-sort-up"></i>
                        </b-button>
                        <b-button
                          size="sm"
                          variant="secondary"
                          :disabled="!relationships_table_ordering_enabled"
                          @click="move(custom_relationships, row.index, 1)"
                        >
                          <i class="fa fa-sort-down"></i>
                        </b-button>
                      </b-button-group>
                    </template>

                    <template v-slot:cell(notes)="row">
                      <nobr>{{ row.item['notes'] }}</nobr>
                    </template>

                    <template v-slot:row-details="row">
                      <div>
                        <b-form-group class="mb-0">
                          <b-input-group>
                            <b-form-input v-model.trim="row.item['from_class_name']" placeholder="From" @input="visualizeDebouncedLong()" />
                            <b-form-input v-model.trim="row.item['relationship_name']" placeholder="Rel. Name" @input="visualizeDebouncedLong()" />
                            <b-form-input v-model.trim="row.item['to_class_name']" placeholder="To" @input="visualizeDebouncedLong()" />
                          </b-input-group>
                        </b-form-group>
                        <b-form-group class="mb-0">
                          <b-input-group>
                            <b-form-input v-model.trim="row.item['group']" placeholder="Group Name" @input="visualizeDebouncedLong()" />
                          </b-input-group>
                        </b-form-group>
                        <b-form-group class="mb-0">
                          <b-input-group>
                            <b-form-input v-model.trim="row.item['notes']" placeholder="Notes" @input="visualizeDebouncedLong()" />
                          </b-input-group>
                        </b-form-group>
                      </div>
                    </template>
                  </b-table>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-col>
          <b-col cols="6" md="6" xl="12">
            <b-card v-if="introspect" no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion_classes variant="light">Classes</b-button>
              </b-card-header>
              <b-collapse id="accordion_classes" accordion="accordion_group" role="tabpanel">
                <b-card-body>
                  <b-button-group size="sm">
                    <b-button
                      @click="
                        () => {
                          initSwitchesIntrospect(true, 'classes');
                          update();
                        }
                      "
                      variant="success"
                    >
                      <i class="fa fa-eye"></i> Show All
                    </b-button>
                    <b-button
                      @click="
                        () => {
                          initSwitchesIntrospect(false, 'classes');
                          update();
                        }
                      "
                      variant="danger"
                    >
                      <i class="fa fa-eye-slash"></i> Hide All
                    </b-button>
                  </b-button-group>

                  <hr />

                  <b-form-group class="mb-0">
                    <b-input-group>
                      <b-form-input v-model.trim="filter_introspect_classes" placeholder="Type to Search" />
                      <b-input-group-append>
                        <b-btn :disabled="!filter_introspect_classes" @click="filter_introspect_classes = ''">Clear</b-btn>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <hr />
                  <b-table
                    hover
                    small
                    responsive
                    striped
                    :fields="[{ key: 'switch', label: '' }, { key: 'class_name', label: 'Class Name', sortable: true }]"
                    :items="table_items_classes"
                    sticky-header="750px"
                  >
                    <template v-slot:cell(switch)="row">
                      <b-button-group size="sm">
                        <div @click="value => update()">
                          <c-switch
                            class="mx-2"
                            v-model="switch_classes[row.item['class_name']]"
                            color="success"
                            size="sm"
                            label
                            outline
                            :dataOn="'\u2713'"
                            :dataOff="'\u2715'"
                            :value="true"
                          />
                        </div>
                        <b-button size="sm" variant="light" :disabled="!switch_classes[row.item['class_name']]" @click="center(row.item['class_name'])">
                          <i class="fa fa-crosshairs"></i>
                        </b-button>
                      </b-button-group>
                    </template>
                  </b-table>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card v-if="introspect" no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion_relationships variant="light">Relationships</b-button>
              </b-card-header>
              <b-collapse id="accordion_relationships" accordion="accordion_group" role="tabpanel">
                <b-card-body>
                  <b-button-group size="sm">
                    <b-button
                      @click="
                        () => {
                          initSwitchesIntrospect(true, 'relationships');
                          update();
                        }
                      "
                      variant="success"
                    >
                      <i class="fa fa-eye"></i> Show All
                    </b-button>
                    <b-button
                      @click="
                        () => {
                          initSwitchesIntrospect(false, 'relationships');
                          update();
                        }
                      "
                      variant="danger"
                    >
                      <i class="fa fa-eye-slash"></i> Hide All
                    </b-button>
                    <b-button
                      @click="
                        () => {
                          initSwitchesIntrospect(true, 'relationships', true);
                          update();
                        }
                      "
                      variant="success"
                    >
                      <i class="fa fa-eye"></i> Show All Listed
                    </b-button>
                    <b-button
                      @click="
                        () => {
                          initSwitchesIntrospect(false, 'relationships', true);
                          update();
                        }
                      "
                      variant="danger"
                    >
                      <i class="fa fa-eye-slash"></i> Hide All Listed
                    </b-button>
                  </b-button-group>

                  <hr />

                  <b-form-group class="mb-0">
                    <b-input-group>
                      <b-form-input v-model.trim="filter_from_class_name" placeholder="From" />
                      <b-form-input v-model.trim="filter_relationship_name" placeholder="Rel. Name" />
                      <b-form-input v-model.trim="filter_to_class_name" placeholder="To" />
                    </b-input-group>
                  </b-form-group>
                  <b-form-group>
                    <c-switch
                      v-b-tooltip.hover
                      title="Filter Virtual Rel."
                      class="mx-2"
                      v-model="filter_virtual_relationships"
                      color="success"
                      label
                      outline
                      :dataOn="'\u2713'"
                      :dataOff="'\u2715'"
                    />
                  </b-form-group>
                  <hr />
                  <b-table
                    hover
                    small
                    responsive
                    striped
                    :fields="[
                      { key: 'switch', label: '' },
                      { key: 'from.class_name', label: 'From Class Name', sortable: true },
                      { key: 'relationship_name', label: 'Relationship Name', sortable: true },
                      { key: 'to.class_name', label: 'To Class Name', sortable: true },
                    ]"
                    :items="table_items_relationships"
                    sticky-header="750px"
                  >
                    <template v-slot:cell(switch)="row">
                      <b-button-group size="sm">
                        <div @click="value => update()">
                          <c-switch
                            class="mx-2"
                            v-model="switch_relationships[getRelationshipId(row.item)]"
                            color="success"
                            size="sm"
                            label
                            outline
                            :dataOn="'\u2713'"
                            :dataOff="'\u2715'"
                            :value="true"
                          />
                        </div>
                        <b-button
                          size="sm"
                          variant="light"
                          :disabled="!switch_relationships[getRelationshipId(row.item)]"
                          @click="center(getRelationshipId(row.item))"
                        >
                          <i class="fa fa-crosshairs"></i>
                        </b-button>
                      </b-button-group>
                    </template>
                  </b-table>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ForceGraph3D from '3d-force-graph';
import * as THREE from 'three';
import SpriteText from 'three-spritetext';
import 'vue-resize/dist/vue-resize.css';

import { ResizeObserver } from 'vue-resize';
import { Switch as cSwitch } from '@coreui/vue';

import store from 'store';

import _ from 'lodash';
import { constants } from 'zlib';

export default {
  name: 'Visualzation3D',
  components: { ResizeObserver, cSwitch },
  props: {},
  data: () => {
    return {
      introspect: null,
      Graph3D: null,

      switch_classes: {},
      switch_relationships: {},

      filter_introspect_classes: '',
      filter_introspect_relationships: '',
      filter_custom_classes: '',
      filter_custom_relationships: '',

      filter_from_class_name: '',
      filter_relationship_name: '',
      filter_to_class_name: '',
      filter_virtual_relationships: false,

      classes_sort_by: null,
      relationships_sort_by: null,

      custom_classes: [],
      custom_relationships: [],

      add_class_name: '',
      add_class_group: '',
      add_class_notes: '',

      add_from_class_name: '',
      add_relationship_name: '',
      add_to_class_name: '',
      add_relationship_group: '',
      add_relationship_notes: '',

      file: null,

      enable_auto_save: false,

      graph_data: {
        nodes: null,
        links: null,
      },
    };
  },
  computed: {
    class_table_ordering_enabled() {
      return _.isNil(this.classes_sort_by) && _.isNil(this.filter_custom_classes);
    },
    relationships_table_ordering_enabled() {
      return _.isNil(this.relationships_sort_by) && _.isNil(this.filter_custom_relationships);
    },
    table_items_classes() {
      if (this.filter_introspect_classes) {
        return this.introspect['classes'].filter(_class => {
          return this.filter_introspect_classes != '' && _class['class_name'].includes(this.filter_introspect_classes);
        });
      }
      return this.introspect['classes'];
    },
    table_items_relationships() {
      return this.introspect['relationships'].filter(relationship => {
        if (this.filter_virtual_relationships) {
          if (relationship['virtual'] == true || relationship['mysql_view'] == true || relationship['subquery'] == true) {
            return false;
          }
        }

        if (this.filter_from_class_name || this.filter_relationship_name || this.filter_to_class_name) {
          return (
            (this.filter_from_class_name != '' && relationship['from']['class_name'].includes(this.filter_from_class_name)) ||
            (this.filter_relationship_name != '' && relationship['relationship_name'].includes(this.filter_relationship_name)) ||
            (this.filter_to_class_name != '' && relationship['to']['class_name'].includes(this.filter_to_class_name))
          );
        }

        return true;
      });
      return this.introspect['relationships'];
    },
    hidden_classes() {
      let hidden_classes = [];
      for (var class_name in this.switch_classes) {
        var value = this.switch_classes[class_name];

        // console.log(class_name, value);

        if (value === false) {
          hidden_classes.push(class_name);
        }
      }
      return hidden_classes;
    },
    hidden_relationships() {
      let hidden_relationships = [];
      for (var relationship_id in this.switch_relationships) {
        var value = this.switch_relationships[relationship_id];

        // console.log(relationship_id, value);

        if (value === false) {
          hidden_relationships.push(relationship_id);
        }
      }
      return hidden_relationships;
    },
  },
  watch: {
    enable_auto_save(to, from) {
      if (this.enable_auto_save) {
        this.setStorage();
      }
    },
  },
  created() {
    this._ = _;
    this.visualizeDebounced = _.debounce(this.visualize, 250);
    this.visualizeDebouncedLong = _.debounce(this.visualize, 1000);
    if (!this.getStorage()) {
      this.request();
    }
  },
  methods: {
    move(array, index, delta) {
      //ref: https://gist.github.com/albertein/4496103
      console.log('move', array, index, delta);

      var newIndex = index + delta;
      if (newIndex < 0 || newIndex == array.length) return; //Already at the top or bottom.
      var indexes = [index, newIndex].sort((a, b) => a - b); //Sort the indixes (fixed)
      array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]); //Replace from lowest index, two elements, reverting the order
    },
    log(...args) {
      console.log(...args);
    },
    toggleDetails(items, toggle_index) {
      console.log('hideAllDetails');
      items.forEach((item, index) => {
        if (index == toggle_index) {
          items[index]._showDetails = !items[index]._showDetails;
        } else {
          items[index]._showDetails = false;
        }
      });
    },
    addClass() {
      var index = this.custom_classes.findIndex(custom_class => {
        return custom_class['class_name'] == this.add_class_name;
      });

      if (index >= 0) {
        console.log('class exists');
        return;
      }

      var custom_class = {};

      custom_class.class_name = this.add_class_name;

      if (this.add_class_notes != '') {
        custom_class.notes = this.add_class_notes;
      }

      if (this.add_class_group != '') {
        custom_class.group = this.add_class_group;
      }
      custom_class['_showDetails'] = false;
      this.custom_classes.push(custom_class);

      // this.add_class_name = ''
      // this.add_class_notes = ''
      // this.add_class_group = ''

      this.update();
    },
    addRelationship() {
      var custom_relationship = {
        from_class_name: this.add_from_class_name,
        relationship_name: this.add_relationship_name,
        to_class_name: this.add_to_class_name,
      };

      if (this.add_relationship_notes != '') {
        custom_relationship.notes = this.add_relationship_notes;
      }

      if (this.add_relationship_group != '') {
        custom_relationship.group = this.add_relationship_group;
      }

      let relationship_id = this.getRelationshipId(custom_relationship);
      var index = this.custom_relationships.findIndex(custom_relationship => {
        return this.getRelationshipId(custom_relationship) == relationship_id;
      });

      if (index >= 0) {
        console.log('relationship exists');
        return;
      }

      this.custom_relationships.push(custom_relationship);

      // this.add_from_class_name = ''
      // this.add_relationship_name = ''
      // this.add_to_class_name = ''
      // this.add_relationship_notes = ''
      // this.add_relationship_group = ''

      this.update();
    },
    removeClass(remove_class_name) {
      var index = this.custom_classes.findIndex(custom_class => {
        return custom_class['class_name'] == remove_class_name;
      });

      // console.log(remove_class_name, index)

      if (index >= 0) {
        this.add_class_name = this.custom_classes[index]['class_name'];
        this.add_class_notes = this.custom_classes[index]['notes'] || '';
        this.add_class_group = this.custom_classes[index]['group'] || '';

        this.custom_classes.splice(index, 1);

        this.update();
      } else {
        console.error('custom class not found');
      }
    },
    removeRelationship(relationship) {
      let relationship_id = this.getRelationshipId(relationship);
      var index = this.custom_relationships.findIndex(custom_relationship => {
        return this.getRelationshipId(custom_relationship) == relationship_id;
      });

      if (index >= 0) {
        this.add_from_class_name = this.custom_relationships[index]['from_class_name'];
        this.add_relationship_name = this.custom_relationships[index]['relationship_name'];
        this.add_to_class_name = this.custom_relationships[index]['to_class_name'];
        this.add_relationship_notes = this.custom_relationships[index]['notes'] || '';
        this.add_relationship_group = this.custom_relationships[index]['group'] || '';

        this.custom_relationships.splice(index, 1);

        this.update();
      } else {
        console.error('custom relationship not found');
      }
    },
    center(id) {
      if (!id) {
        this.Graph3D.cameraPosition(
          { x: 0, y: 0 },
          2000 // ms transition duration
        );
      } else {
        console.log('id', id);
        console.log('graph_data', this.graph_data);

        let node = _.find(this.graph_data['nodes'], node => {
          return node.id == id;
        });
        console.log('node', node);

        let link = _.find(this.graph_data['links'], link => {
          return link.id == id;
        });
        console.log('link', link);

        if (node || link) {
          const distance = 200;

          let x, y, z;
          if (node) {
            x = node.x;
            y = node.y;
            z = node.z;
          } else if (link) {
            x = (link.source.x + link.target.x) / 2;
            y = (link.source.y + link.target.y) / 2;
            z = (link.source.z + link.target.z) / 2;
          }

          const distRatio = 1 + distance / Math.hypot(x, y, z);
          console.log('distRatio', distRatio);

          this.Graph3D.cameraPosition(
            {
              x: x * distRatio,
              y: y * distRatio,
              z: z * distRatio,
            }, // new position
            // node, // lookAt ({ x, y, z })
            { x, y, z }, // lookAt ({ x, y, z })
            0 // ms transition duration
          );
        }
      }
    },
    handleResize() {
      var container_width = this.$refs.graph_3d_container.offsetWidth;
      var container_height = this.$refs.graph_3d_container.offsetHeight;
      // console.log('resized', container_width);

      if (this.Graph3D) {
        this.Graph3D.width(container_width);
        this.Graph3D.height(container_height);
      }
    },
    clearIntrospect() {
      this.introspect = null;
      this.update();
    },
    request() {
      this.$NProgress.start();
      this.error_message = null;
      this.success_message = null;
      this.$api
        .get('/descriptor/introspect')
        .then(response => {
          // success callback
          var data = this.$api.getData(response);
          if (data) {
            if (this.dataType in data) {
              this.success_message = this.successMessage;
            }
            this.introspect = data;

            this.update();
          }
        })
        .catch(axios_error => {
          // error callback
          var error = this.$api.getError(axios_error);
          if (error) {
            this.error_message = this.$api.getValidation(error);
          }
        })
        .then(() => {
          this.$NProgress.done();
          if (!this.success_message && !this.error_message) {
            this.error_message = this.$api.defaultErrorMessage;
          }
        });
    },
    getRelationshipId(relationship) {
      if (relationship['from'] && relationship['to']) {
        return relationship['from']['class_name'] + '-' + relationship['relationship_name'] + '-' + relationship['to']['class_name'];
      }
      return relationship['from_class_name'] + '-' + relationship['relationship_name'] + '-' + relationship['to_class_name'];
    },
    cleanSwitches() {
      var cleaned_switch_classes = {};
      var cleaned_switch_relationships = {};

      let default_state = true;

      if (this.introspect) {
        this.introspect['classes'].forEach(class_ => {
          var class_name = class_['class_name'];
          cleaned_switch_classes[class_name] = _.get(this.switch_classes, [class_name], default_state);
        });

        this.introspect['relationships'].forEach(relationship => {
          var relationship_id = this.getRelationshipId(relationship);
          cleaned_switch_relationships[relationship_id] = _.get(this.switch_relationships, [relationship_id], default_state);
        });
      }

      this.custom_classes.forEach(class_ => {
        var class_name = class_['class_name'];
        cleaned_switch_classes[class_name] = _.get(this.switch_classes, [class_name], default_state);
      });

      this.custom_relationships.forEach(relationship => {
        var relationship_id = this.getRelationshipId(relationship);
        cleaned_switch_relationships[relationship_id] = _.get(this.switch_relationships, [relationship_id], default_state);
      });

      cleaned_switch_classes = this.sortObjectKeys(cleaned_switch_classes);
      cleaned_switch_relationships = this.sortObjectKeys(cleaned_switch_relationships);

      this.switch_classes = cleaned_switch_classes;
      this.switch_relationships = cleaned_switch_relationships;
    },
    sortObjectKeys(not_sorted) {
      let sorted = Object.keys(not_sorted)
        .sort()
        .reduce(function(acc, key) {
          acc[key] = not_sorted[key];
          return acc;
        }, {});
      return sorted;
    },
    initSwitchesIntrospect(show = null, entity_type, listed_only = false) {
      var force = true;
      if (show == null) {
        force = false;
        show = true;
      }
      if (this.introspect) {
        if (entity_type == 'classes' || entity_type == 'all') {
          (listed_only ? this.table_items_classes : this.introspect['classes']).forEach(class_ => {
            // this.switch_classes[class_['class_name']] = show;
            var class_name = class_['class_name'];
            if (force || !(class_name in this.switch_classes) || (this.switch_classes[class_name] !== true && this.switch_classes[class_name] !== false)) {
              this.$set(this.switch_classes, class_name, show);
            }
          });
        }

        if (entity_type == 'relationships' || entity_type == 'all') {
          (listed_only ? this.table_items_relationships : this.introspect['relationships']).forEach(relationship => {
            // this.switch_relationships[class_['class_name']] = show;
            var relationship_id = this.getRelationshipId(relationship);
            if (
              force ||
              !(relationship_id in this.switch_relationships) ||
              (this.switch_relationships[relationship_id] !== true && this.switch_relationships[relationship_id] !== false)
            ) {
              this.$set(this.switch_relationships, relationship_id, show);
            }
          });
        }
      }

      this.cleanSwitches();
    },
    initSwitchesCustom(show = null) {
      var force = true;
      if (show == null) {
        force = false;
        show = true;
      }
      this.custom_classes.forEach(class_ => {
        // this.switch_classes[class_['class_name']] = show;
        var class_name = class_['class_name'];
        if (force || !(class_name in this.switch_classes) || (this.switch_classes[class_name] !== true && this.switch_classes[class_name] !== false)) {
          this.$set(this.switch_classes, class_name, show);
        }
      });

      this.custom_relationships.forEach(relationship => {
        // this.switch_relationships[class_['class_name']] = show;
        var relationship_id = this.getRelationshipId(relationship);
        if (
          force ||
          !(relationship_id in this.switch_relationships) ||
          (this.switch_relationships[relationship_id] !== true && this.switch_relationships[relationship_id] !== false)
        ) {
          this.$set(this.switch_relationships, relationship_id, show);
        }
      });

      this.cleanSwitches();
    },
    initGraph() {
      //ref: https://github.com/vasturiano/3d-force-graph/blob/master/example/directional-links-arrows/index.html
      // https://vasturiano.github.io/3d-force-graph/example/directional-links-arrows/
      // Random tree
      /* const N = 40;
      const gData = {
        nodes: [...Array(N).keys()].map(i => ({ id: i })),
        links: [...Array(N).keys()]
          .filter(id => id)
          .map(id => ({
            source: id,
            target: Math.round(Math.random() * (id - 1))
          }))
      }; */

      var self = this;
      function updateGeometries() {
        // self.Graph3D.nodeRelSize(4); // trigger update of 3d objects in scene
        // self.Graph3D.linkResolution(8)  // trigger update of 3d objects in scene

        self.Graph3D.backgroundColor('#000');
      }

      let highlightNodes = [];
      let highlightLink = null;

      var elem = this.$refs.graph_3d;

      this.Graph3D = ForceGraph3D({
        rendererConfig: {
          antialias: true, //does not seem to make any difference
          // antialias: false,
        },
      })(elem)
        // .width(1000)
        // .backgroundColor('#FFF')
        .linkDirectionalArrowLength(4)
        .linkDirectionalArrowRelPos(1)
        // .linkCurvature(0.25) //too thin to see!
        .linkWidth(1) //some how removes curvature
        // .nodeColor(node => (highlightNodes.indexOf(node) === -1 ? 'rgba(255,255,255,0.8)' : 'rgba(29, 155, 198, 0.8)'))
        // .linkColor('#000') //doesn't work
        // .linkDirectionalArrowColor('#000')  //doesn't work
        /* .onNodeHover(node => {
          // no state change
          if ((!node && !highlightNodes.length) || (highlightNodes.length === 1 && highlightNodes[0] === node)) return;
          highlightNodes = node ? [node] : [];
          updateGeometries();
        }) */

        // .linkDirectionalParticles(4)
        .linkDirectionalParticles(link => (link === highlightLink ? 4 : 0))
        .linkDirectionalParticleWidth(1.5)
        // .linkDirectionalParticleColor('#000') //doesn't work
        .onLinkHover(link => {
          // no state change
          if (highlightLink === link) return;
          highlightLink = link;
          highlightNodes = link ? [link.source, link.target] : [];
          updateGeometries();
        })

        .nodeLabel(d => `<span style="color: rgba(255,255,255, 0.8)">${d.name}${d.notes ? ': ' + d.notes : ''}</span>`)
        // .linkLabel(d => `<span style="color: rgba(255,255,255, 0.8)">${d.name}</span>`)

        .nodeAutoColorBy('group')
        .linkAutoColorBy('group')
        .linkOpacity(0.5)
        .nodeThreeObject(node => {
          // use a sphere as a drag handle
          const obj = new THREE.Mesh(
            new THREE.SphereGeometry(10),
            // new THREE.MeshBasicMaterial({ depthWrite: false, opacity: 1 })
            new THREE.MeshBasicMaterial({ depthWrite: false, transparent: true, opacity: 0 })
          );
          const sprite = new SpriteText(node.name);
          sprite.color = node.color;
          // sprite.color = 'white';
          sprite.textHeight = 4;
          obj.add(sprite);
          return obj;
        });

      // Spread nodes a little wider
      // this.Graph3D.d3Force('charge').strength(-150);

      //Hoever works but Click does not work
      // .onNodeHover(node => (elem.style.cursor = node ? 'pointer' : null))
      /* .onNodeClick(node => {
          // Aim at node from outside it
          const distance = 100;
          const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);
          Graph.cameraPosition(
            { x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio }, // new position
            node, // lookAt ({ x, y, z })
            2000 // ms transition duration
          );
        }) */

      //should have been set to proper pixelRatio by default, but we'd want super resolution
      var super_resolution_level = 2;
      this.Graph3D.renderer().setPixelRatio(window.devicePixelRatio * super_resolution_level);

      this.handleResize();
    },
    update() {
      //SANITIZE DATA

      this.initSwitchesIntrospect();
      this.initSwitchesCustom();

      // console.log(this.switch_classes);
      // console.log(this.switch_relationships);

      this.visualizeDebounced();
    },
    visualize() {
      //GENERATE GRAPH DATA

      let graph_nodes = [];

      if (this.introspect) {
        this.introspect['classes'].forEach(class_ => {
          if (!this.hidden_classes.includes(class_['class_name'])) {
            graph_nodes.push({
              id: class_['class_name'],
              name: class_['class_name'],
            });
          }
        });
      }

      this.custom_classes.forEach(class_ => {
        if (!this.hidden_classes.includes(class_['class_name'])) {
          graph_nodes.push({
            id: class_['class_name'],
            name: class_['class_name'],
            group: class_['group'],
            notes: class_['notes'],
          });
        }
      });

      console.log('graph_nodes', graph_nodes);

      let graph_links = [];

      if (this.introspect) {
        this.introspect['relationships'].forEach(relationship => {
          var relationship_id = this.getRelationshipId(relationship);
          if (!this.hidden_relationships.includes(relationship_id)) {
            var source = relationship['from']['class_name'];
            var target = relationship['to']['class_name'];

            var has_source = graph_nodes.find(function(node) {
              return node['id'] == source;
            });
            var has_target = graph_nodes.find(function(node) {
              return node['id'] == target;
            });

            // console.log(has_source, has_target);

            if (has_source && has_target) {
              graph_links.push({
                id: relationship_id,
                // name: relationship['relationship_name'],
                // name: relationship_id,
                name: relationship['relationship_name'],
                source: source,
                target: target,
              });
            } else {
              if (!has_source) {
                // console.error('missing source: ', source);
              }

              if (!has_target) {
                // console.error('missing target: ', target);
              }
            }
          }
        });
      }

      this.custom_relationships.forEach(relationship => {
        var relationship_id = this.getRelationshipId(relationship);
        if (!this.hidden_relationships.includes(relationship_id)) {
          var source = relationship['from_class_name'];
          var target = relationship['to_class_name'];

          var has_source = graph_nodes.find(function(node) {
            return node['id'] == source;
          });
          var has_target = graph_nodes.find(function(node) {
            return node['id'] == target;
          });

          // console.log(has_source, has_target);

          if (has_source && has_target) {
            graph_links.push({
              id: relationship_id,
              // name: relationship['relationship_name'],
              // name: relationship_id,
              name:
                relationship['relationship_name'] +
                (relationship['group'] ? ' (' + relationship['group'] + ') ' : '') +
                (relationship['notes'] ? ': ' + relationship['notes'] : ''),
              source: source,
              target: target,
              group: relationship['group'],
              // notes: relationship['notes']
            });
          } else {
            if (!has_source) {
              console.error('missing source: ', source);
            }

            if (!has_target) {
              console.error('missing target: ', target);
            }
          }
        }
      });

      console.log('graph_links', graph_links);

      //RENDER GRAPH DATA

      this.graph_data = {
        nodes: graph_nodes,
        links: graph_links,
      };
      this.updateGraphData(this.graph_data);

      //SAVE DATA

      //save for every changes made
      if (this.enable_auto_save) {
        this.setStorage();
      }
    },
    updateGraphData(graph_data) {
      if (!this.Graph3D) {
        this.initGraph();
      }
      this.Graph3D.graphData(graph_data);
    },
    generateStorage() {
      var graph_3d_storage = {};

      graph_3d_storage.enable_auto_save = this.enable_auto_save;

      graph_3d_storage.introspect = this.introspect;

      graph_3d_storage.switch_classes = this.switch_classes;
      graph_3d_storage.switch_relationships = this.switch_relationships;

      graph_3d_storage.filter_introspect_classes = this.filter_introspect_classes;
      graph_3d_storage.filter_introspect_relationships = this.filter_introspect_relationships;
      graph_3d_storage.filter_custom_classes = this.filter_custom_classes;
      graph_3d_storage.filter_custom_relationships = this.filter_custom_relationships;

      // graph_3d_storage.custom_classes = this.custom_classes;

      graph_3d_storage.custom_classes = [];
      if (this.custom_classes) {
        this.custom_classes.forEach(custom_class => {
          let temp_custom_class = Object.assign({}, custom_class);
          delete temp_custom_class['_showDetails'];
          graph_3d_storage.custom_classes.push(temp_custom_class);
        });
      }

      // graph_3d_storage.custom_relationships = this.custom_relationships;

      graph_3d_storage.custom_relationships = [];
      if (this.custom_relationships) {
        this.custom_relationships.forEach(custom_relationship => {
          let temp_custom_relationship = Object.assign({}, custom_relationship);
          delete temp_custom_relationship['_showDetails'];
          graph_3d_storage.custom_relationships.push(temp_custom_relationship);
        });
      }

      return graph_3d_storage;
    },
    extractStorage(graph_3d_storage) {
      this.enable_auto_save = graph_3d_storage.enable_auto_save || false;

      this.introspect = graph_3d_storage.introspect || null;

      this.switch_classes = graph_3d_storage.switch_classes || {};
      this.switch_relationships = graph_3d_storage.switch_relationships || {};

      this.filter_introspect_classes = graph_3d_storage.filter_introspect_classes || '';
      this.filter_introspect_relationships = graph_3d_storage.filter_introspect_relationships || '';
      this.filter_custom_classes = graph_3d_storage.filter_custom_classes || '';
      this.filter_custom_relationships = graph_3d_storage.filter_custom_relationships || '';

      // this.custom_classes = graph_3d_storage.custom_classes || [];

      this.custom_classes = [];
      if (graph_3d_storage.custom_classes) {
        graph_3d_storage.custom_classes.forEach(custom_class => {
          custom_class['_showDetails'] = false;
          this.custom_classes.push(custom_class);
        });
      }

      // this.custom_relationships = graph_3d_storage.custom_relationships || [];

      this.custom_relationships = [];
      if (graph_3d_storage.custom_relationships) {
        graph_3d_storage.custom_relationships.forEach(custom_relationship => {
          custom_relationship['_showDetails'] = false;
          this.custom_relationships.push(custom_relationship);
        });
      }

      this.update();
    },
    getStorage() {
      var graph_3d_storage = store.get('graph_3d');
      if (graph_3d_storage) {
        console.log('has graph_3d_storage');
        this.extractStorage(graph_3d_storage);
        return true;
      } else {
        console.log('no graph_3d_storage');
        return false;
      }
    },
    setStorage() {
      var graph_3d_storage = this.generateStorage();
      store.set('graph_3d', graph_3d_storage);
      console.log('set graph_3d_storage');
    },
    clearStorage() {
      this.clearData();
      store.remove('graph_3d');
      console.log('clear graph_3d_storage');
    },
    clearData() {
      console.log('clear data');
      this.extractStorage({});
    },
    download(content, fileName, contentType) {
      //ref: https://stackoverflow.com/a/34156339/3553367
      var a = document.createElement('a');
      var file = new Blob([content], { type: contentType });
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    },
    exportJSON() {
      this.download(JSON.stringify(this.generateStorage(), null, 2), 'graph_3d_export_' + Date.now() + '.json', 'application/json');
    },
    importJSON() {
      var self = this;
      //ref: https://stackoverflow.com/a/50610003/3553367
      var fileread = new FileReader();
      fileread.onload = function(e) {
        var content = e.target.result;
        // console.log(content);

        var graph_3d_storage = JSON.parse(content);
        self.extractStorage(graph_3d_storage);

        // console.log(graph_3d_storage)
      };
      fileread.readAsText(this.file);
      // console.log(this.file)
    },
  },
};
</script>

<style>
.graph_3d_container {
  width: 100%;
  height: calc(100vh - 200px);
  display: block;
}
</style>
